<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="homePage" ref="toTop">
    <!-- <van-sticky class="headerTitle">
      <van-nav-bar v-if="$route.name !== 'home'" :title="getTitle()" left-text="" left-arrow
        @click-left="$router.go(-1)" />
      </van-sticky> -->
    <!-- 打开app -->
    <!-- <div class="openApp">
      <div class="openAppL">
        <img src="@/assets/img/home/appLogo.png" alt="">
        <div class="title">
          <div>智汇学堂APP</div>
          <div>读书破万卷 下笔如有神</div>
        </div>
      </div>
      <div class="oppeAppR" @click="openApp">
        打开App
      </div>
    </div> -->
    <!-- 打开智汇学堂 -->
    <!-- <div class="openApp2">
      <span @click="downEvent">下载智汇学堂 即刻开始学习 ></span>
    </div> -->
    <template v-if="$route.name == 'home'">
      <!-- 置顶 -->
      <van-sticky class="toTop" :offset-bottom="50" :offset-top="550" position="bottom">
        <van-button @click='scrollToTop'><img src="@/assets/img/home/upIcon.png" alt=""></van-button>
      </van-sticky>
      <!-- <div class="toTop">
      </div> -->
      <!-- 轮播 -->
      <div class="carouselContent" id="carousel">
        <van-swipe :autoplay="3000" lazy-render>
          <van-swipe-item v-for="(carouselItem, carouselIndex) in carouselList" :key="carouselIndex">
            <img :src="carouselItem?.imgUrl" @click="handleCarouseClick(carouselItem)" />
          </van-swipe-item>
        </van-swipe>
      </div>
    </template>
    <!-- logo、菜单 -->
    <van-sticky id="headerR" ref="menuNav" :offset-top="offsetTop" :class="['header', { 'menuNav': $route.name !== 'home' }]">
      <div class="headerL">
        <img :src="infoImgs[0]?.imgUrl || ''" alt="" @click="goto(infoImgs[0].linkUrl)">
      </div>
      <div class="headerR">
        <van-popover get-container="#headerR" v-model="showNav" :actions="navList" placement="bottom-end"
          @select="handleNavClick">
          <template #reference>
            <img src="@/assets/img/home/menuIcon.png" alt="" @click="handleNavIconClick" srcset="">
          </template>
        </van-popover>
      </div>
    </van-sticky>
    <div class="homeMain" :class="[{ 'newsPage': $route.name == 'homePageDetails' }]">
      <template v-if="$route.name == 'home'">
        <!-- 模块内容 -->
        <div :class="['content', { 'last': index == infoList.length - 1 }]" v-for="(item, index) in infoList" :key="index">
          <template v-if="item.innerShow == 1">
            <div class="contentTitle">
              <div>
                <span>{{ item.showName }}</span>
                <div class="line"></div>
              </div>
              <img v-if="item.goto" src="@/assets/img/home/right.png" @click="$way.goto(item.goto, item.query)" alt="">
              <span v-else></span>
            </div>
            <div
              :class="['contentMain', { 'notice': item.uniqueFlag == 4 }, { 'news': item.uniqueFlag == 3 }, { 'major': item.uniqueFlag == 6 }, { 'majorCenter': item.uniqueFlag == 6 && hotMajors.length < 3 }, { 'station': item.uniqueFlag == 7 }]">
              <template v-if="item.uniqueFlag == 4">
                <div class="noticeItem" v-for="(noticeItem, noticeIndex) in Notices" :key="noticeIndex"
                  @click="$way.goto('homePageDetails', { type: 1, id: noticeItem.id, style: 'h5' })">
                  <div class="itemMain noticeItemMain">
                    <img class="add" src="@/assets/img/home/add.png" alt="" srcset="">
                    <div class="text">{{ noticeItem.newTitle }}</div>
                  </div>
                  <div class="time">{{ $way.timestampReturnDate(noticeItem.publisherTime, 'dateYMD') }}</div>
                </div>
              </template>
              <div v-if="item.uniqueFlag == 2" class="introduction"
                @click="$way.goto('homePageDetails', { labelName: '学校简介', ...schoolInfo, style: 'h5' })">
                <div class="imgBox">
                  <!-- <img src="@/assets/img/home/appLogo.png" alt="" srcset=""> -->
                  <img :src="infoImgs[4]?.imgUrl || `${$store.state.aliBaseUrl}/images/202202/school_1645524889695.png`"
                    alt="" />
                </div>
                <div class="text">{{ schoolInfo.briefIntroductionContent }}</div>
              </div>
              <div v-if="item.uniqueFlag == 3" class="news">
                <div class="newItem" v-for="(newItem, newIndex) in newsList" :key="newIndex"
                  @click="$way.goto('homePageDetails', { type: 0, id: newItem.id, style: 'h5' })">
                  <template v-if="newIndex == 0">
                    <div class="imgBox">
                      <img
                        :src="newItem?.imgUrl ? newItem?.imgUrl : (infoImgs[3]?.imgUrl || 'https://file.zhihuischool.cn/images/202201/news_1642745124602.png')"
                        alt="" srcset="">
                      <div class="title"><span>{{ newItem.newTitle }}</span>
                        <img src="@/assets/img/home/downIcon.png" alt="">
                      </div>
                    </div>
                    <div class="text">{{ newItem.content }}</div>
                  </template>
                  <template v-else>
                    <div class="newItemTitle">
                      <div class="time">
                        <span>{{ $way.timestampReturnDate(newItem.publisherTime, 'dateD') }}</span>
                        <span>{{ $way.timestampReturnDate(newItem.publisherTime, 'dateYM') }}</span>
                      </div>
                      <img
                        :src="flagList[parseInt(schoolInfo.homeColor) == 2 ? 'green' : (parseInt(schoolInfo.homeColor) == 1 ? 'red' : 'blue')]"
                        alt="">
                      <div class="title">{{ newItem.newTitle }}</div>
                    </div>
                    <div class="newItemMain">
                      建设以大健康为特色的创业型职业技术大学一、办学基本情况广东岭南职业技术学院始办于1993年的“广州岭南文化技术学校”，2001年5月经广东省人民政府批准成立。学院举办方为广州岭南教育集团有限公司。
                    </div>
                  </template>
                  <div class="line"></div>
                </div>
              </div>
              <van-swipe ref="vanSwipe" v-if="item.uniqueFlag == 6" :autoplay="3000" @change="majorChange">
                <van-swipe-item v-for="(majorItem, majorIndex) in hotMajors" :key="majorIndex">
                  <!-- <div class="div">

                  </div> -->
                  <img v-if="majorItem?.imgUrl" :src="majorItem?.imgUrl || ''" />
                  <div class="majorBox">
                    <div class="majorTitle"></div>
                    <span>{{ majorItem.name }}</span>
                  </div>
                </van-swipe-item>
              </van-swipe>
              <div v-if="item.uniqueFlag == 5" class="introduction"
                @click="$way.goto('homePageDetails', { type: 2, id: brochures.id, style: 'h5' })">
                <div class="imgBox">
                  <img
                    :src="brochures && brochures?.imgUrl ? brochures?.imgUrl : (infoImgs[5]?.imgUrl || `${$store.state.aliFileUrl}/images/202201/graduation_1643083343274.jpg`)"
                    alt="" srcset="">
                </div>
                <!-- <img :src="brochures && infoImgs[5].imgUrl?infoImgs[5].imgUrl:`${$store.state.aliFileUrl}/images/202201/graduation_1643083343274.jpg`" alt="" srcset=""></div> -->
                <div class="text" v-if="brochures && brochures.content">{{ brochures.content }}</div>
              </div>
              <template v-if="item.uniqueFlag == 7">
                <template v-for="(stationItem, stationIndex) in stations">
                  <div v-if="stationIndex < 6" class="stationItem" :key="stationIndex"
                    @click="handleStationClick(stationIndex)">
                    <img v-if="stationItem?.imgUrl" class="logo" :src="stationItem?.imgUrl" alt="">
                    <span>{{ stationItem.majorName }}</span>
                    <div class="circle"></div>
                    <div class="stationInfo" v-show="stationItem.showInfo">
                      <div>
                        <!-- <img src="@/assets/img/home/linkMan.png" alt=""> -->
                        <span>联系人：{{ stationItem.linkman }}</span>
                      </div>
                      <div>
                        <!-- <img src="@/assets/img/home/phone.png" alt=""> -->
                        <span>电&nbsp;&nbsp;&nbsp;话：{{ stationItem.phone }}</span>
                      </div>
                    </div>
                  </div>
                  <transition name="fade" v-else>
                    <div v-show="showStation" class="stationItem" :key="stationIndex"
                      @click="handleStationClick(stationIndex)">
                      <img v-if="stationItem?.imgUrl" class="logo" :src="stationItem?.imgUrl" alt="">
                      <span>{{ stationItem.majorName }}</span>
                      <div class="circle"></div>
                      <div class="stationInfo" v-show="stationItem.showInfo">
                        <div>
                          <!-- <img src="@/assets/img/home/linkMan.png" alt=""> -->
                          <span>联系人：{{ stationItem.linkman }}</span>
                        </div>
                        <div>
                          <!-- <img src="@/assets/img/home/phone.png" alt=""> -->
                          <span>电&nbsp;&nbsp;&nbsp;话：{{ stationItem.phone }}</span>
                        </div>
                      </div>
                    </div>
                  </transition>
                </template>
              </template>
            </div>
            <div class="extend" v-if="stations.length > 6 && item.uniqueFlag == 7">
              <img v-if="showStation" src="@/assets/img/home/putAway.png" alt="" @click="showStation = !showStation">
              <img v-else src="@/assets/img/home/open.png" alt="" @click="showStation = !showStation">
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        <router-view></router-view>
      </template>
      <homeFooter :schoolInfo="schoolInfo"></homeFooter>
    </div>
    <pagetips ref="pagetips"></pagetips>
  </div>
</template>

<script>
import homeFooter from '@/components/home/homeFooter'
import pagetips from '@/components/commonModule/pagetips'
// import { Swiper, SwiperSlide } from 'swiper/vue';
// import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/swiper-bundle.css";
export default {
  name: 'home',
  components: {
    homeFooter,
    pagetips
  },
  data () {
    return {
      schoolName: '',
      href: '',
      offsetTop: 55,
      showNav: false,
      showStation: false,
      navList: [
        // { text: '首页', moduleName: '首页', goto: 'home' },
        // { text: '学校简介', moduleName: '学校简介', goto: 'homePageDetails', query: { labelName: '学校简介', ...this.schoolInfo, style: 'h5' } },
        // {
        //   text: '招生简章', moduleName: '招生简章', goto: 'homePageList', query: {
        //     type: 2
        //   }
        // },
        // {
        //   text: '新闻动态', moduleName: '新闻动态', goto: 'homePageList', query: {
        //     type: 0
        //   }
        // },
        // {
        //   text: '通知公告', moduleName: '通知公告', goto: 'homePageList', query: {
        //     type: 1
        //   }
        // },
      ],
      schoolInfo: {},
      infoList: [
        {
          id: 4,
          moduleName: '通知公告',
          goto: 'homePageList',
          query: {
            type: 1
          }
        },
        {
          id: 2,
          moduleName: '学校简介'
        },
        {
          id: 3,
          moduleName: '新闻动态',
          goto: 'homePageList',
          query: {
            type: 0
          }
        },
        {
          id: 5,
          moduleName: '招生简章',
          goto: 'homePageList',
          query: {
            type: 2
          }
        },
        {
          id: 6,
          moduleName: '热门专业'
        },
        {
          id: 7,
          moduleName: '校外教学点'
        }
      ],
      infoImgs: [],
      carouselList: [],
      Notices: [],
      newsList: [],
      hotMajors: [],
      brochures: {},
      stations: [],
      downurls: '',
      flagList: {
        blue: require('@/assets/img/home/flagBlue.png'),
        green: require('@/assets/img/home/flagGreen.png'),
        red: require('@/assets/img/home/flagRed.png')
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal) {
          // console.log(newVal)
          if (newVal) {
            if (newVal.name == 'home') {
              this.offsetTop = 55
              if (sessionStorage.getItem('schoolInfo')) {
                const info = JSON.parse(sessionStorage.getItem('schoolInfo'))
                if (info.name) {
                  document.title = info.name
                }
              }
              this.scrollToTop()
            } else if (newVal.name == 'homePageDetails') {
              this.offsetTop = 55
              console.log('object', this.offsetTop)
              if (newVal.query.type || newVal.query.type == 0 || newVal.query.labelName) {
                this.changeName(newVal.query.type, '详情', newVal.query.labelName)
              }
            } else if (newVal.name == 'homePageList') {
              if (newVal.query.type || newVal.query.type == 0) {
                this.changeName(newVal.query.type)
              }
            }
          }
        }
      }
    }
  },
  beforeCreate () {

  },
  created () {
    const hostname = location.hostname
    // hostname = 'al.gf5184.com';
    // let hostname = 'yddx.zhihuischool.cn/#/home'
    // http://nc.gf5184.com
    this.href = hostname.split('.')[0]
    this.schoolName = this.href
    this.getSchoolInfoList()
    const type = this.$store.state.deviceType == 1 ? 2 : 1
    console.log(this.$store.state.deviceType, 'deviceType')
    if (this.$store.state.deviceType < 4) {
      this.getNewVersionEvent(type)
    } else {
      this.downurls = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.czh.zhxt'
      // this.$refs.pagetips.showEvent();
    }
  },
  methods: {
    handleCarouseClick (item) {
      // if(this.schoolInfo.id == 3) window.open('https://ecutgz.zhihuischool.cn/h5/#/dhLive')
      if (item.imgLink) window.open(item.imgLink)
    },
    // 改变页面名称
    changeName (type, title, labelName) {
      let name = ''
      if (type == 1) {
        name = '通知公告'
      } else if (type == 2) {
        name = '招生简章'
      } else if (type == 0) {
        name = '新闻动态'
      }
      if (labelName) {
        name = labelName
      }
      const infoList = JSON.parse(sessionStorage.getItem('infoList'))
      console.log(infoList, 'infoList3')
      for (const i in infoList) {
        if (infoList[i].moduleName == name) {
          if (title) {
            if (labelName) {
              document.title = labelName
            } else {
              document.title = infoList[i].showName + title
            }
          } else {
            document.title = infoList[i].showName
          }
          break
        }
      }
    },
    // 专业轮播改变
    majorChange (idx) {
      if (idx == this.hotMajors.length - 1) {
        this.$refs.vanSwipe[0].swipeTo(0)
      }
    },
    // 置顶
    scrollToTop () {
      if (this.$refs.toTop) {
        this.$nextTick(function () {
          this.$refs.toTop.scrollTo({ top: 0, behavior: 'smooth' })
        })
      }
    },
    // 打开app
    openApp () {
      const type = this.$store.state.deviceType
      if (type == 1) {
        window.location.href = 'com.czh.zhxt.app://'
      } else if (type == 2) {
        window.location.href = 'android://com.czh.zhxt/open'
      } else if (type == 4 || type == 5 || type == 6 || type == 7) {
        // this.Info('选择浏览器打开该页面,再进行app打开');
        this.$refs.pagetips.showEvent()
      }
    },
    // 获取下载地址
    getNewVersionEvent (type) {
      this.$request.getNewVersion({ type }).then(res => {
        if (res.data.code == 0) {
          this.downurls = res.data.data.downurls
        }
      })
    },
    // 立即下载
    downEvent () {
      if (!this.downurls) {
        this.Info('暂时无法下载')
        return
      }
      // window.location.href = this.downurls;
      window.location.replace(this.downurls)
    },
    // 获取标题
    getTitle () {
      if (this.$route.query.labelName) {
        return this.$route.query.labelName
      } else {
        return this.$route.query.type == 0 ? '新闻动态' : (this.$route.query.type == 1 ? '通知公告' : '招生简章')
      }
    },
    goto (url) {
      window.location.href = url
    },
    // 导航点击
    handleNavIconClick () {
      this.showNav = !this.showNav
      // console.log('object',this.showNav);
    },
    // 导航点击
    handleNavClick (item) {
      if (item.linkFlag == 1 && item.linkUrl) { window.location.href = item.linkUrl } else { this.$way.goto(item.goto, item.query) }
    },
    // 教学点点击
    handleStationClick (index) {
      for (const i in this.stations) {
        if (this.stations[i].showInfo) {
          this.stations[i].showInfo = !this.stations[i].showInfo
          this.stations[index].showInfo = !this.stations[index].showInfo
          break
        } else if (i == this.stations.length - 1) {
          this.stations[index].showInfo = !this.stations[index].showInfo
          break
        }
      }
    },
    // 获取当前学校信息
    getSchoolInfoList () {
      const params = {
        encoding: this.schoolName || 'yhdx'
      }
      this.$request.getSchoolInfo(params).then((res) => {
        if (res.data.code == 0) {
          // this.schoolInfo = res.data.data;
          this.$set(this, 'schoolInfo', res.data.data)
          sessionStorage.setItem('tenant_id', this.schoolInfo.id)
          sessionStorage.setItem('schoolInfo', JSON.stringify(this.schoolInfo))
          // document.title = this.schoolInfo.name;
          console.log(this.schoolInfo, 'schoolInfo')
          console.log(document.title)
          if (this.$route.name == 'home') {
            document.title = this.schoolInfo.name
          }
          // 改变主题颜色
          if (this.schoolInfo.homeColor == 1) {
            console.log('红色主题')
            document.getElementsByTagName('body')[0].style.setProperty('--theme-color', '#A13838')
            document.getElementsByTagName('body')[0].style.setProperty('--theme-color2', '#910B0B')
          } else if (this.schoolInfo.homeColor == 2) {
            console.log('绿色主题')
            document.getElementsByTagName('body')[0].style.setProperty('--theme-color', '#009B4C')
            document.getElementsByTagName('body')[0].style.setProperty('--theme-color2', '#007A3C')
          }
          // this.showHome = true;
          // this.exhibitModule = []
          this.getSchoolImgInfo()
          this.getSchoolCarousel()
          if (this.schoolInfo.moduleVos.length > 0) {
            // this.exhibitModule = this.schoolInfo.exhibitModule.split(',')
            for (const i in this.schoolInfo.moduleVos) {
              for (const j in this.infoList) {
                if (this.schoolInfo.moduleVos[i].uniqueFlag == this.infoList[j].id) {
                  const a = this.infoList[j]
                  this.infoList[j] = {
                    ...a,
                    ...this.schoolInfo.moduleVos[i]
                  }
                  if (this.schoolInfo.moduleVos[i].uniqueFlag == 1) {
                  } else if (this.schoolInfo.moduleVos[i].uniqueFlag == 2) {
                  } else if (this.schoolInfo.moduleVos[i].uniqueFlag == 5 && this.schoolInfo.moduleVos[i].innerShow == 1) {
                    this.getSchoolBrochure()
                  } else if (this.schoolInfo.moduleVos[i].uniqueFlag == 3 && this.schoolInfo.moduleVos[i].innerShow == 1) {
                    this.getSchoolNewsList()
                  } else if (this.schoolInfo.moduleVos[i].uniqueFlag == 4 && this.schoolInfo.moduleVos[i].innerShow == 1) {
                    this.getSchoolNotices()
                  } else if (this.schoolInfo.moduleVos[i].uniqueFlag == 6 && this.schoolInfo.moduleVos[i].innerShow == 1) {
                    this.getSchoolHotMajors()
                  } else if (this.schoolInfo.moduleVos[i].uniqueFlag == 7 && this.schoolInfo.moduleVos[i].innerShow == 1) {
                    this.getSchoolStations()
                  }
                  break
                }
              }
            }
            // this.navList = null
            for (let i = 0; i < this.schoolInfo.moduleVos.length - 1; i++) {
              if (this.schoolInfo.moduleVos[i].barShow == 1) {
                // console.log(this.schoolInfo.moduleVos[i].showName)
                const obj = {
                  text: this.schoolInfo.moduleVos[i].showName,
                  goto: 'home',
                  query: {},
                  ...this._.cloneDeep(this.schoolInfo.moduleVos[i])
                }
                if (this.schoolInfo.moduleVos[i].uniqueFlag == 1) {
                  // obj.goto = 'home'
                } else if (this.schoolInfo.moduleVos[i].uniqueFlag == 2) {
                  obj.goto = 'homePageDetails'
                  obj.query = { labelName: '学校简介', ...this.schoolInfo, style: 'h5' }
                } else if (this.schoolInfo.moduleVos[i].uniqueFlag == 5) {
                  obj.goto = 'homePageList'
                  obj.query = { type: 2 }
                } else if (this.schoolInfo.moduleVos[i].uniqueFlag == 3) {
                  obj.goto = 'homePageList'
                  obj.query = { type: 0 }
                } else if (this.schoolInfo.moduleVos[i].uniqueFlag == 4) {
                  obj.goto = 'homePageList'
                  obj.query = { type: 1 }
                } else if (this.schoolInfo.moduleVos[i].uniqueFlag == 10) {
                  obj.goto = 'graduteInfo'
                  obj.query = { }
                } else if (this.schoolInfo.moduleVos[i].uniqueFlag == 11) {
                  obj.goto = 'graduteInfo'
                  obj.query = { type: 'luqu' }
                }
                this.navList.push(obj)
              }
            }
            // let navArr = []
            // this.schoolInfo.moduleVos.forEach(ele => {
            //   if (ele.barShow == 1) {
            //     ele.text = ele.showName
            //     if (ele.uniqueFlag == 1) {
            //       ele.goto = 'home'
            //       ele.query = {}
            //     } else if (ele.uniqueFlag == 2) {
            //       ele.goto = 'homePageDetails'
            //       ele.query = { labelName: '学校简介', ...this.schoolInfo, style: 'h5' }
            //     } else if (ele.uniqueFlag == 3) {
            //       ele.goto = 'homePageList'
            //       ele.query = { type: 0 }
            //     } else if (ele.uniqueFlag == 4) {
            //       ele.goto = 'homePageList'
            //       ele.query = { type: 1 }
            //     } else if (ele.uniqueFlag == 5) {
            //       ele.goto = 'homePageList'
            //       ele.query = { type: 2 }
            //     }
            //   }
            // })
            // this.navList.push({
            //   text: '教师登录',
            //   goto: 'login',
            //   query: {}
            // })
            sessionStorage.setItem('infoList', JSON.stringify(this.infoList))
          }
        } else if (res.data.code == 1) {
          // this.$route.push('404')
        }
      }).catch((err) => { })
    },
    // 获取图片初始化设置
    getSchoolImgInfo () {
      const params = {
        tenantId: this.schoolInfo.id
      }
      this.$request.getSchoolImgInfo(params)
        .then((res) => {
          if (res.data.code == 0) {
            console.log(res.data, 'imglist')
            this.infoImgs = res.data.data
            this.schoolInfo.footImg = this.infoImgs[2]?.imgUrl
            this.schoolInfo.footImgName = this.infoImgs[2].name
            this.schoolInfo.footImgUrl = this.infoImgs[2].linkUrl
            sessionStorage.setItem('schoolInfo', JSON.stringify(this.schoolInfo))
          }
        })
        .catch((err) => { })
    },
    // 获取轮播
    getSchoolCarousel () {
      const params = {
        tenantId: this.schoolInfo.id
      }
      this.$request.getSchoolCarousel(params)
        .then((res) => {
          if (res.data.code == 0) {
            this.carouselList = res.data.data
            console.log(this.carouselList)
          }
        })
        .catch((err) => { })
    },
    // 获取公告
    getSchoolNotices () {
      const params = {
        tenantId: this.schoolInfo.id
      }
      this.$request.getSchoolNotices(params)
        .then((res) => {
          if (res.data.code == 0) {
            this.Notices = res.data.data
          }
        })
        .catch((err) => { })
    },
    // 获取新闻动态
    getSchoolNewsList () {
      const params = {
        tenantId: this.schoolInfo.id
      }
      this.$request.getSchoolNews(params)
        .then((res) => {
          if (res.data.code == 0) {
            this.newsList = res.data.data
            // if (res.data.data.length === 1) {
            //   this.newsList0 = res.data.data[0];
            //   console.log(this.newsList0);
            // } else {
            //   console.log('object1');
            //   this.newsList0 = res.data.data[0];
            //   console.log(this.newsList0);
            //   this.newsList1 = res.data.data;
            //   this.newsList1.splice(0, 1);
            //   let obj = {
            //     ...res.data.data[0]
            //   }
            //   this.newsList1.splice(1, 0, obj)
            //   console.log('object', this.newsList1);
            // }
          }
        })
        .catch((err) => { })
    },
    // 获取招生简章
    getSchoolBrochure () {
      const params = {
        tenantId: this.schoolInfo.id
      }
      this.$request.getSchoolBrochure(params).then((res) => {
        if (res.data.code == 0) {
          this.brochures = res.data.data[0]
        }
      }).catch((err) => { })
    },
    // 获取学校校外教学点
    getSchoolStations () {
      const params = {
        tenantId: this.schoolInfo.id
      }
      this.$request.getSchoolStations(params)
        .then((res) => {
          if (res.data.code == 0) {
            this.stations = res.data.data.map((item) => {
              return {
                ...item,
                showInfo: false
              }
            })
            // if (res.data.data.length > 12) {
            //   for (let i = 0; i < 12; i++) {
            //     let dot = true
            //     if (i>7) {
            //       dot = false
            //     }
            //     let obj = {
            //       ...res.data.data[i],
            //       showTips:false,
            //       showDot: dot
            //     }
            //     this.stations.push(obj);
            //   }
            //   this.showMore = true;
            //   console.log(this.stations,'stations', this.stations.length)
            //   console.log(this.stationALL,'stationALL', this.stationALL.length)
            // } else {
            //   res.data.data.forEach(item => {
            //     let obj2 = {
            //       ...item,
            //       showTips: false,
            //       showDot: true
            //     }
            //     this.stations.push(obj2);
            //   });
            //   var num1 = this.stations.length / 4;
            //   var num2 = this.stations.length % 4;
            //   console.log(num1, num2);
            //   if (num2 === 2) {
            //     document
            //       .getElementsByTagName("body")[0]
            //       .style.setProperty("--contListAW", "49.5%");
            //   }
            //   this.showDot(num1,num2)
            // }
          }
        })
        .catch((err) => { })
    },
    // 获取热门专业
    getSchoolHotMajors () {
      const params = {
        tenantId: this.schoolInfo.id
      }
      this.$request.getSchoolHotMajors(params).then((res) => {
        if (res.data.code == 0) {
          this.hotMajors = res.data.data
          // console.log(this.hotMajors.length);
          // this.hotMajors.length = 3
          // console.log(this.hotMajors.length);
          // if (this.hotMajors.length <= 5) {
          //   console.log("feed");
          //   this.swiperOption.slidesPerView = this.hotMajors.length;
          //   this.swiperOption.slidesPerGroup = this.hotMajors.length;
          //   console.log(this.swiperOption);
          // } else {
          //   this.swiperOption.autoplay = true;
          //   this.swiperOption.loop = true;
          //   this.swiperOption.freeMode = false;
          // }
        }
      }).catch((err) => { })
    }
  }
}
</script>

<style lang='scss'></style>
<style lang='scss' scoped>
// :root {
//   --theme-color: #1a7ee2;
//   --theme-color2: #0b4e91;
//   --theme-color-hover: #ff8400;
//   // --contListAW: 288px;
// }
$theme-color: var(--theme-color, #1a7ee2);
$theme-color2: var(--theme-color2, #0b4e91);
$theme-color-hover: var(--theme-color-hover, '#ff8400');

::v-deep .van-nav-bar {
  .van-nav-bar__content {
    height: 44px !important;
    z-index: 50;

    .van-nav-bar__left {
      .van-icon-arrow-left {
        color: #828282;
      }
    }
  }
}

.homePage {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #F9F9F9;
  overflow: auto;
  // overflow: scroll;
  // width: 100%;
  // height: max-content;
  padding-bottom: constant(safe-area-inset-bottom); // 兼容 IOS<11.2/
  // padding-bottom: env(safe-area-inset-bottom); // 兼容 IOS>11.2/
  padding-bottom: calc(0.1rem + env(safe-area-inset-bottom));

  .headerTitle {
    z-index: 50;
  }

  .newsPage {
    // height: 92vh;
    padding-top: 8vh;
  }

  // height: calc(100vh - 60px);
  .openApp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 28px 11px 24px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.06);
    position: fixed;
    width: calc(100% - 52px);
    background: #FFFFFF;
    z-index: 55;

    .openAppL {
      display: flex;
      align-items: center;

      img {
        width: 40px;
        height: 39px;
      }

      .title {
        display: flex;
        flex-flow: column;
        padding-left: 10px;
      }
    }

    .oppeAppR {
      width: 76px;
      height: 28px;
      background: #1A7EE2;
      border-radius: 6px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 28px;
      text-align: center;
    }
  }

  .openApp2 {
    position: fixed;
    bottom: 20px;
    display: flex;
    width: 100%;
    z-index: 55;

    span {
      background: #1A7EE2;
      color: #FFFFFF;
      padding: 14px 35px;
      // box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 6%);
      border-radius: 22px;
      margin: 0 auto;
    }
  }

  .toTop {
    height: 0px !important;

    ::v-deep .van-sticky {
      display: inline-block;
      width: 80px;
      right: -10px !important;
      left: auto;

      & .van-button {
        padding: 0 14px;
        height: 50px;
        width: 50px;
        border-radius: 8px;
        margin-right: 12px;
        border: 1px solid #EFEFEF;

        //     border: none;
        // background: none;
        .van-button__content {
          // padding: 13px 14px;
          // height: 24px;
          // background: #FFF;
          // border-radius: 8px;
        }
      }
    }

    img,
    .van-button__text {
      height: 24px;
    }
  }

  .carouselContent {
    padding: 73px 24px 13px;
    background: #FFFFFF;
    clear: both !important;

    & .van-swipe {
      width: 100%;
      height: 190px;
      background: #D8D8D8;
      border-radius: 6px;

      .van-swipe__track {
        & .van-swipe-item {
          & img {
            width: 100%;
            height: 100%;
          }
        }

      }
    }
  }

  & .header {
    &.menuNav {
      height: 44px;

      ::v-deep .van-sticky {
        top: 60px;
        position: fixed;
        width: calc(100% - 48px);
        z-index: 99;
      }
    }

    // height: 70px;
    ::v-deep .van-sticky {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 11px 24px;
      background: #FFFFFF;

      .headerL {
        width: 90%;
        overflow: hidden;
        height: 22px;

        img {
          height: 100%;
        }
      }

      .headerR {
        height: 16px;

        .van-popover__wrapper {
          display: block;
          height: 16px;

          img {
            height: 16px;
            width: 20px;
          }
        }
      }
    }

    ::v-deep .van-popup {
      // background: red;
      width: 110px !important;

      .van-popover__arrow {
        display: none !important;
      }

      & .van-popover__content {
        .van-popover__action {
          width: 110px !important;

          &:last-child {
            color: #999999;
          }

          .van-popover__action-text {
            word-break: break-all;
          }
        }
      }
    }
  }

  .content {
    padding: 0 24px 0px;

    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    &.last {
      padding-bottom: 40px;
    }

    .contentTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 40px 0 19px 0;

      div {
        span {
          height: 18px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #363840;
          line-height: 18px;
        }

        .line {
          height: 3px;
          background: $theme-color;
          opacity: 0.2;
          position: relative;
          top: -2px;
          border-radius: 5px;
        }
      }

      img {
        width: 9px;
        height: 16px;
        padding-left: 15px;
      }
    }

    .contentMain {
      background: #FFFFFF;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      border-radius: 6px;
      border: 1px solid #EFEFEF;
      overflow: hidden;

      .fade-enter-active {
        animation: mymove .5s;
      }

      .fade-leave-active {
        // transition: opacity 0.5s;
        animation: mymove2 .5s;
        // animation-iteration-count: 1;
      }

      // animation: mymove 3s infinite;
      @keyframes mymove {
        from {
          opacity: 0;
        }

        to {
          opacity: 0.8;
        }
      }

      @keyframes mymove2 {
        from {
          opacity: 0.8;
        }

        to {
          opacity: 0;
        }
      }

      // .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      // }
      &.notice {
        padding: 0 10px;
      }

      &.majorCenter {
        display: flex;
        justify-content: center;

        & .majorBox {
          .majorTitle {
            opacity: 1 !important;
          }
        }
      }

      &.major {
        background: none;
        border: none;

        .van-swipe {
          width: 45%;
          height: 180px;
          overflow: initial;

          ::v-deep .van-swipe__indicators {
            display: none;
          }

          & .van-swipe-item {
            // margin-right: 10px;
            border-radius: 5px;
            overflow: hidden;

            .majorBox {
              position: absolute;
              bottom: 0;
              width: calc(100% - 10px);

              .majorTitle {
                background: $theme-color;
                opacity: 0.6;
                padding: 20px 0;
                height: 14px;
                border-bottom-right-radius: 5px;
              }

              span {
                position: absolute;
                color: #FFFFFF;
                text-align: center;
                top: 30%;
                width: 100%;
              }
            }

            img {
              width: calc(100% - 10px);
              height: 100%;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
          }
        }
      }

      .noticeItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px 0;
        border-bottom: 1px solid #EFEFEF;

        &:last-child {
          border-bottom: none;
        }

        .itemMain {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #363840;

          &.noticeItemMain {
            display: flex;
            align-items: baseline;
            width: 75%;
          }

          .add {
            width: 10px;
            height: 10px;
            margin-right: 6px;
          }

          .text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
        }

        .time {
          height: 12px;
          font-size: 12px;
          color: #999999;
          line-height: 12px;
        }
      }

      .introduction {
        .imgBox {
          width: 100%;
          height: 196px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .text {
          margin: 20px 16px;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
      }

      .news {
        .newItem {
          margin-top: 18px;
          line-height: 24px;

          &:first-child {
            margin-top: 0px;
          }

          &:last-child {
            margin-bottom: 24px;

            .line {
              display: none;
            }
          }

          .newItemTitle {
            display: flex;
            align-items: center;
            height: 52px;
            padding: 0 20px 0 12px;

            .title {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              word-break: break-all;
              font-size: 14px;
              color: #363840;
              line-height: 26px;
            }

            .time {
              display: flex;
              flex-flow: column;
              align-items: center;

              span {
                &:first-child {
                  height: 30px;
                  font-size: 30px;
                  color: #363840;
                  line-height: 30px;
                }

                &:last-child {
                  color: #999999;
                  line-height: 12px;
                  height: 12px;
                  padding-top: 4px;
                }
              }
            }

            img {
              height: 90%;
              margin: 0 10px 0 7px;
            }
          }

          .newItemMain {
            margin: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }

          .line {
            background: #E5E5E5;
            height: 1px;
            margin: 0 16px;
          }

          .imgBox {
            width: 100%;
            height: 184px;
            position: relative;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
            }

            .title {
              background-color: rgba(0, 0, 0, 0.6);
              border-radius: 6px 6px 0px 0px;
              position: absolute;
              top: 0;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              flex-flow: column;

              span {
                width: 180px;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 32px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              img {
                width: 10px;
                height: 6px;
                margin-top: 22px;
              }
            }
          }

          .text {
            margin: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
        }
      }

      &.station {
        background: none;
        border: none;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 8px 6px;

        .stationItem {
          // width: 48%;
          height: 24px;
          // overflow: hidden;
          background: #FFFFFF;
          border-radius: 6px;
          border: 1px solid #EFEFEF;
          padding: 20px 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          span {
            color: #363840;
            line-height: 12px;
          }

          .stationInfo {
            position: absolute;
            background: $theme-color;
            width: calc(100% - 24px);
            height: 100%;
            justify-content: center;
            display: flex;
            flex-flow: column;
            padding: 0 12px 0 12px;
            border-radius: 6px;

            span {
              color: #FEFEFE;
              line-height: 14px;
              height: 14px;
              font-size: 12px;
              word-break: break-all;
            }

            div {
              &:first-child {
                margin-bottom: 8px;
              }

              img {
                width: 12px;
                height: 10px;
                padding-right: 5px;
              }
            }
          }

          .circle {
            background: $theme-color;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            position: absolute;
            right: -6px;
            bottom: -7px;
          }

          .logo {
            max-height: 24px;
            width: auto;
            margin-right: 5px;
          }
        }
      }
    }

    .extend {
      padding-top: 15px;
      display: flex;
      justify-content: center;

      img {
        width: 23px;
        height: 18px;
      }
    }
  }
}</style>
