<template>
  <div :class="['footer',{'footer2':$route.name=='homePageDetails'}]">
    <div class="footerTop">
      <div :class="['phone',{'width100':!schoolInfoChild.footImg}]" v-if="schoolInfoChild.phones&&schoolInfoChild.phones.length>0">
        <div class="phoneL">联系电话：</div>
        <div class="phoneR">
          <span v-for="(item, index) in schoolInfoChild.phones" :key="index">{{item}}</span>
        </div>
      </div>
      <div class="footerTopR" v-if="schoolInfoChild.footImg">
        <template v-if="schoolInfoChild.footImg">
          <img :src="schoolInfoChild.footImg||'@/assets/img/home/QRCode.png'" alt="" srcset="" @click="goto(schoolInfoChild.footImgUrl)">
          <span @click="goto(schoolInfoChild.footImgUrl)">{{ schoolInfoChild.footImgName || ''}}</span>
        </template>
      </div>
      <div class="address">
        <div class="title">学校地址：</div>
        <span>{{schoolInfoChild.address}}</span>
      </div>
      <div class="weChats">
        <div class="title">微信客服：</div>
        <span @click="jumpWeChat">点击在线客服</span>
      </div>
      <!-- <div class="footerTopL">
      </div> -->
    </div>
    <div class="footerBottom">
      <!-- <span>© 2021-2022 广州市传知汇科技有限公司版权所有</span> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'homeFooter',
  props: ['schoolInfo'],
  watch: {
    schoolInfo: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal) {
          console.log(newVal)
          this.$set(this, 'schoolInfoChild', newVal)
          this.getContact()
        }
      }
    }
  },
  data() {
    return {
      schoolInfoChild: {},
      customerUrl: null
    }
  },
  methods: {
    goto(url) {
      window.location.href = url
    },
    getContact() {
      this.$request.getContactPhone().then((res) => {
        if (res.data.code == 0) {
          this.customerUrl = res.data.data.customerUrl
        }
      });
    },
    jumpWeChat() {
      window.location.href = this.customerUrl
    },
  },
}
</script>
<style lang="scss" scoped>
.footer {
  background: #2F3034;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 16px;
  &.footer2{
    // height: 176px !important;
    overflow: hidden;
    .footerBottom{
      padding-bottom: 24px;
    }
  }
  .footerTop {
    padding: 15px 24px 10px;
    // display: flex;
    // justify-content: space-between;
    .phone {
      float: left;
      width: 72%;
      margin: 0px 0 6px 0;
      display: flex;
      align-items: baseline;
      &.width100 {
        width: 100%;
      }
      .phoneL {
        // flex: 2;
        // width: 65px;
      }
      .phoneR {
        flex: 6;
        span {
          display: block;
          margin-bottom: 6px;
        }
      }
    }
    // .footerTopL {
    //   flex: 4;
    //   div {
    //   }
    // }

    .footerTopR {
      float: right;
      display: flex;
      flex-flow: column;
      align-items: center;
      img {
        width: 80px;
        height: 79px;
      }
      span {
        margin-bottom: 10px;
        margin-top: 7px;
      }
    }
    .address {
      clear: left;
      display: flex;
      margin-bottom: 10px;
      .title{

      }
      span {
        flex: 6;
      }
    }
    .weChats {
      display: flex;
      margin-bottom: 10px;
      span {
        color: #3470d8;
        cursor: pointer;
      }
    }
  }

  .footerBottom {
    border-top: 1px solid #666666;
    padding: 16px 51px 80px;
    clear: both;
    span {}
  }
}
</style>
